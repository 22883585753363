import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_sp_service_page';

const serviceRoute = [
    {
        path: '/services',
        name: 'services.access',
        exact: true,
        component: () => import('@/pages/services/ServiceSubscriptionMain.vue'),
        meta: {
            auth: true,
            permission: ['services.access', 'eup.services.access-services'],
            title : 'Service Subscriptions'
        },
    },
    {
        path: '/services/:id',
        name: 'eup-service-details',
        exact: true,
        component: () => import('@/pages/services/details/ServiceSubscriptionDetails.vue'),
        meta: {
            auth: true,
            permission: ['eup.services.access-services','eup.services.view-services'],
            title : 'Service Details',
            prefix: 'eup-service-details',
        },
    },
];

export default applyPageGroupKeyToParents(serviceRoute, { pageGroupKey });
