import axios from 'axios';
import * as http from '@/core/apis';
import { useUserStore } from '@/stores/user-store';
import { useAuthStore } from '@/stores/authStore';

const nimbusToken = 'nimbus-token';
const nimbusRefereshToken = 'nimbus-refresh-token';

export default class UserAuthenticationService {
    /**
     * Set value token in local storage
     *
     * @param accessToken
     */
    setBearer(accessToken) {
        localStorage.setItem(nimbusToken, accessToken);
        localStorage.setItem(nimbusRefereshToken, accessToken);
    }

    /**
     * Storage to hold all the data the bearer
     *
     * @param data
     */
    setBearerSession(data) {
        localStorage.setItem('nimbus-session', JSON.stringify(data));
    }

    /**
     * Set bearer token for request
     *
     * @returns {{headers: {Authorization: string}}}
     */
    getBearer() {
        const token = localStorage.getItem(nimbusToken);
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        return config;
    }

    /**
     * Get access token
     * @returns {string}
     */
    getToken() {
        return localStorage.getItem(nimbusToken);
    }

    /**
     * set user id in local storage
     */
    setAuthenticatedUserID(id) {
        localStorage.setItem('nimbus-authenticated-user-id', id);
    }

    /**
     * get user id in local storage
     */
    getAuthenticatedUserID() {
        return parseInt(localStorage.getItem('nimbus-authenticated-user-id'));
    }

    /**
     * delete an existing property in local storage
     *
     * @param key
     */
    removeItemInLocalStorage(key) {
        localStorage.removeItem(key);
    }

    /**
     * remove user id in local storage
     */
    removeAuthenticatedUserID() {
        localStorage.removeItem('nimbus-authenticated-user-id');
    }

    /**
     * Get logged in user
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAuthenticatedUser() {
        return axios
            .get('api/v1/authenticated-user', this.getBearer())
            .then((res) => {
                /**
                 * TODO:
                 *  DEV-4640; subject for removal, as this is being handled by authStore. But needs to check the app
                 *  for its occurrences first. State management shouldn't be here, separating responsibility.
                 */
                let store = useUserStore();
                this.setAuthenticatedUserID(res.data.data.id);
                store.setUserAuthenticatedDetails(res.data.data);

                return res.data.data;
            })
            .catch((error) => {
                // if return as 401 remove the token on this part
                if (error.response.status === 401) {
                    this.setBearer(null);
                    this.removeAuthenticatedUserID();
                }

                return error;
            });
    }

    /**
     * Check user if logged in
     */
    isAuthenticated() {
        let authToken = localStorage.getItem(nimbusToken);
        return !(authToken === 'null' || authToken === null);
    }

    /**
     * Set visited-url in localstorage
     *
     * @param url
     */
    setVisitedUrl(url) {
        localStorage.setItem('visited-url', url);
    }

    /**
     * Set prev-visited-url in localstorage
     *
     * @param url
     */
    setPreviousVisitedUrl(url) {
        localStorage.setItem('prev-visited-url', url);
    }

    /**
     * get value of visited-url in local storage
     *
     * @returns {string}
     */
    getPreviousVisitedUrl() {
        return localStorage.getItem('prev-visited-url');
    }

    /**
     * get value of visited-url in local storage
     *
     * @returns {string}
     */
    getVisitedUrl() {
        return localStorage.getItem('visited-url');
    }

    /**
     * remove entry in local storage
     */
    removeVisitedUrl() {
        localStorage.removeItem('visited-url');
    }

    /**
     * Check visited url is exists
     * @returns {boolean}
     */
    hasVisitedUrl() {
        return localStorage.getItem('visited-url') !== null;
    }

    /**
     * Fetch and cache user permissions
     * @return boolean
     */
    async cachePermission() {
        // refactor using the default core apis
        try {
            const response = await http.get('user/permissions');
            localStorage.setItem('permissions', JSON.stringify(response));
        } catch (error) {
            console.error(error);
        }

        /**
         * NOTE: Commented this for now for future reference
         */
        // return new Promise(async (resolve, reject) => {
        //     try {
        //         const response = http.get('user/permissions');
        //
        //         console.log(response);
        //         localStorage.setItem('permissions', JSON.stringify(response));
        //         resolve(true);
        //     } catch (error) {
        //         reject(error);
        //     }
        // });
    }

    async loadPermissions() {
        try {
            return await axios.get('/api/v1/user/permissions', this.getBearer());
        } catch (error) {
            console.error(error);
        }
    }

    async loadNavigations() {
        try {
            return await http.get('navigation');
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Remove cached user permissions
     * @return boolean
     */
    removePermissions() {
        localStorage.removeItem('permissions');
    }

    /**
     * Get cached user permissions
     * @return array
     */
    getPermissions() {
        let localPermissions = localStorage.getItem('permissions');
        try {
            return JSON.parse(localPermissions !== null ? localPermissions : '[]');
        } catch (e) {
            console.error('getPermissions:: Parsing Error: ', e.name, e.message);
            return [];
        }
    }

    /**
     * check if user has cached Permission
     * @return boolean
     */
    hasPermission(permission) {
        let permissions = this.getPermissions();
        return permissions.includes(permission);
    }

    /**
     * Set value 2FA Status in local storage
     *
     * @param status
     */
    setTwoFactorAuthentication(status) {
        localStorage.setItem('nimbus-two-factor-authentication', status);
    }

    /**
     * get value of 2FA in local storage
     *
     * @returns {string}
     */
    getTwoFactorAuthentication() {
        return localStorage.getItem('nimbus-two-factor-authentication');
    }
}

export async function getUserAccount() {
    return await http.get(`/user/current-account`);
}

/**
 * Set value token in local storage
 *
 * @param accessToken
 */
export async function eupSetBearer(accessToken) {
    localStorage.setItem(nimbusToken, accessToken);
    localStorage.setItem(nimbusRefereshToken, accessToken);
}

/**
 * Login authentication endpoint
 * @param {*} payload 
 * @returns 
 */
export async function authenticate(payload) {
    return await api.post(`login`, payload)
}

export async function getUserRealm(token) {
    const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
    }

    return await http.get('user-realm', headerConfig)
}
