import * as api from '@/core/apis';
export const BANK_ACCOUNT_METHOD_ID = 1;
export const CREDIT_CARD_METHOD_ID = 10;

export async function getPaymentMethods() {
    return await api.get(`/payment-method`);
}

export async function getCreditCardSubType() {
    return await api.get(`/payment-method/sub-types`);
}

export async function setAsDefaultCreditCardPaymentMethod(accountId, creditCardId, tokenRequestId) {
    return await api.get(`/payment-method/credit-card/set-default/` + accountId + `/` + creditCardId + `/` + tokenRequestId);
}

export async function setAsDefaultCreditCardPaymentMethodWithoutTokenId(accountId, creditCardId) {
    return await api.get(`/payment-method/credit-card/set-default/` + accountId + `/` + creditCardId);
}

export async function createCreditCardPaymentMethod(accountId, creditCardDetails) {
    return await api.post('/payment-method/credit-card/' + accountId, creditCardDetails)
}

export async function updateCreditCardPaymentMethod(accountId, creditCardDetails) {
    return await api.post(`/payment-method/credit-card/${accountId}/update`, creditCardDetails)
}

export async function getAvailableCreditCardsApi(accountId) {
    return await api.get(`/payment-method/credit-card/${accountId}/available`)
}

export async function removeDefaultCreditCardApi(accountId) {
    return await api.post(`/payment-method/credit-card/${accountId}/remove-default`)
}

export async function setAsDefaultBankAccountPaymentMethod(accountId, bankAccountId, tokenRequestId) {
    return await api.get(`/payment-method/bank-account/set-default/` + accountId + `/` + bankAccountId + `/` + tokenRequestId);
}

export async function createBankAccountPayment(accountId, bankAccountDetails) {
    return await api.post('/payment-method/bank-account/' + accountId, bankAccountDetails)
}

export async function getAutomatedPaymentMethods() {
    return await api.get('/payment-method/automated-payment-methods')
}

export async function getPaymentInfo(accountId) {
    return await api.get('/payment-method/automated-payment-methods/' + accountId)
}

export async function getPaymentInfoStatus() {
    return await api.get('/payment-method/status')
}

export async function createAutomatedPaymentMethod(accountId, data) {
    return await api.post('/payment-method/automated-payment-methods/' + accountId, data)
}


export async function createCreditCardWithPaymentOption(accountId, data) {
    return await api.post(`/payment-method/credit-card/${accountId}/with-payment-option`  , data)
}

export async function getCreditCardSurcharges(accountId) {
    return await api.get(`/payment-method/credit-card-surcharges?account_id=${accountId}`);
}

export async function getBankAccountCardSurcharges(accountId) {

    return await api.get(`/payment-method/bank-account-surcharges?account_id=${accountId}`);
}

export async function getAvailablePaymentMethod() {
    return await api.get(`/payment-method/available-option`);
}

export async function getUsablePayMethodsApi() {
    return await api.get(`/payment-method/usable-payment-method`);
}

export async function updateEffectiveToBankAccount(id, payload) {
    return await api.post(`/payment-method/bank-account/${id}/update`, payload)
}

export async function getAvailableBankAccounts(id, payload)  {
    return await api.post(`/payment-method/bank-account/${id}/available`, payload)
}

export async function updateNewDefaultBankAccount(id) {
    return await api.get(`/payment-method/bank-account/${id}/set-default`)
}

export async function switchAutomatedPaymentMethodIdApi(data) {
    return await api.post(`payment-method/automated-payment-methods/${data.account_id}/switch-type`, data)
}


export const CARD_LIBRARY_DROPDOWN_DETAILS = {
    'Visa': {
        label: 'Visa',
        id: 1,
        iconHeight: '0.75rem',
    },
    'MasterCard': {
        label: 'Mastercard',
        id: 2,
        iconHeight: '0.75rem',
    },
    'AmericanExpress': {
        label: 'American Express',
        shortLabel: 'Amex',
        id: 3,
        iconHeight: '1.15rem',
    },
    'Diners': {
        label: 'Diners',
        id: 4,
        iconHeight: '1.15rem',
    },
    'Discover': {
        label: 'Discover',
        id: 5,
        iconHeight: '1.15rem',
    },
    'JCB': {
        label: 'JCB',
        id: 6,
        iconHeight: '1.15rem',
    },
    'DirectDebit': {
        label: isUSLanguage.value ? 'Auto Clearing House' : 'Direct Debit',
        id: 6,
        iconHeight: '0.75rem',
    }
};


export function isUSLanguage() {
    let nimbusLanguage = localStorage.getItem('nimbus-selected-language')
    return nimbusLanguage === 'en-us'
};

