const page = 'modules-features';

export default [
    {
        path: '/core/' + page,
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/core/modules_features/Index'),
        meta: {
            auth: true,
            permission: ['module.access', 'feature.access'],
            title: 'Modules and Features',
            prefix: `${page}-main`
        },
        children: [
            {
                path: `/core/modules/mapping`,
                name: `${page}-mapping`,
                exact: true,
                component: () => import('@/pages/core/modules_features/mapping/MappingMain'),
                meta: {
                    auth: true,
                    permission: ['core.modules-and-features.access'],
                    title: 'Mapping',
                    prefix: `${page}-mapping`,
                },
            },
            {
                path: `/core/modules`,
                name: `${page}-modules`,
                exact: true,
                component: () => import('@/pages/core/modules_features/module/Index'),
                meta: {
                    auth: true,
                    permission: ['module.access'],
                    title: 'Modules',
                    prefix: `${page}-modules`,
                },
            },
            {
                path: `/core/features`,
                name: `${page}-features`,
                exact: true,
                component: () => import('@/pages/core/modules_features/feature/Index'),
                meta: {
                    auth: true,
                    permission: ['feature.access'],
                    title: 'Features',
                    prefix: `${page}-features`,
                },
            },
            {
                path: '/core/feature/create',
                name: 'Create Feature',
                exact: true,
                component: () => import('@/pages/core/modules_features/feature/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['feature.create'],
                    title: 'Feature Create',
                },
            },
            {
                path: '/core/feature/edit/:id',
                name: 'Edit Feature',
                exact: true,
                component: () => import('@/pages/core/modules_features/feature/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['feature.edit'],
                    title: 'Feature Edit',
                },
            },
            {
                path: '/core/feature/:id',
                name: 'View Feature',
                exact: true,
                component: () => import('@/pages/core/modules_features/feature/View.vue'),
                meta: {
                    auth: true,
                    permission: ['feature.view'],
                    title: 'View Feature',
                },
            },
            {
                path: '/core/module/create',
                name: 'Create Module',
                exact: true,
                component: () => import('@/pages/core/modules_features/module/Create.vue'),
                meta: {
                    auth: true,
                    permission: ['module.create'],
                    title: 'Create Module',
                },
            },
            {
                path: '/core/module/edit/:id',
                name: 'Module Edit',
                component: () => import('@/pages/core/modules_features/module/Edit.vue'),
                meta: {
                    auth: true,
                    permission: ['module.edit'],
                    title: 'Edit Module',
                },
            },
            {
                path: '/core/module/:id',
                name: 'View Module',
                exact: true,
                component: () => import('@/pages/core/modules_features/module/View.vue'),
                meta: {
                    auth: true,
                    permission: ['module.view'],
                    title: 'View Module',
                },
            },
            {
                path: '/core/module/:id/account',
                name: 'View Module Account',
                exact: true,
                component: () => import('@/pages/core/modules_features/module/Account.vue'),
                meta: {
                    auth: true,
                    permission: ['module.id.account.access'],
                },
            },
        ]
    },

];
