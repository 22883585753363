import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import UserAuthenticationService from '@/service/UserAuthenticationService';
import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia/dist/pinia';

export const useAuthStore = defineStore(
    'auth',
    () => {
        const authService = new UserAuthenticationService();
        const
            userStore = useUserStore(),
            { isEup } = storeToRefs(userStore);

        /**
         * States
         */
        /** auth details */
        const details = ref(null);
        /** permissions */
        const permissions = ref([]);
        /** navigations */
        const navigations = ref(null);

        /**
         * Getters
         */
        const defaultCurrency = computed(() => details.value?.defaultCurrency);
        /**
         * Realm will be concatenated to the username for SPP, otherwise just plain username.
         * For EUP, remove the last occurrence of "@" which assumed to be as the realm.
         */
        const displayedUsername = computed(() => {
            const username = details.value?.username ?? '';
            return isEup.value ? username.slice(0, username.lastIndexOf('@')) : username;
        });

        /**
         * Actions
         */
        async function loadDetails() {
            const storedDetails = localStorage.getItem('nimbus-auth-details');
            if (storedDetails) {
                details.value = JSON.parse(storedDetails);
                return;
            }

            if (details.value && Object.keys(details.value).length > 0) {
                localStorage.setItem('nimbus-auth-details', JSON.stringify(details.value));
                localStorage.setItem('nimbus-currency', JSON.stringify(defaultCurrency.value));
                return;
            }

            const res = await authService.getAuthenticatedUser();
            details.value = res;
            localStorage.setItem('nimbus-auth-details', JSON.stringify(res));
            localStorage.setItem('nimbus-currency', JSON.stringify(defaultCurrency.value));
        }

        async function loadPermissions() {
            const storedPermissions = localStorage.getItem('permissions');
            if (storedPermissions) {
                permissions.value = JSON.parse(storedPermissions);
                return;
            }

            if (permissions.value?.length > 0) {
                localStorage.setItem('permissions', JSON.stringify(permissions.value));
                return;
            }

            const res = await authService.loadPermissions();
            permissions.value = res.data;
            localStorage.setItem('permissions', JSON.stringify(res.data));
        }

        async function loadNavigations() {
            const storedNav = localStorage.getItem('nimbus-navigations');
            if (storedNav) {
                navigations.value = JSON.parse(storedNav);
                return;
            }

            if (navigations.value && Object.keys(navigations.value).length > 0) {
                localStorage.setItem('nimbus-navigations', JSON.stringify(navigations.value));
                return;
            }

            const res = await authService.loadNavigations();
            navigations.value = res;
            localStorage.setItem('nimbus-navigations', JSON.stringify(res));
        }

        function clearAuthStates() {
            details.value = {};
            permissions.value = [];
            navigations.value = {};
        }

        return {
            /**
             * States
             */
            details,
            permissions,
            navigations,

            /**
             * Getters
             */
            defaultCurrency,
            displayedUsername,

            /**
             * Actions
             */
            loadDetails,
            clearAuthStates,
            loadPermissions,
            loadNavigations,
        };
    },
);