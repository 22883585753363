import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_accounts';

const userRoute = [
    {
        path: '/user',
        name: 'account.user.access',
        exact: true,
        component: () => import('@/pages/user/Index'),
        meta: {
            auth: true,
            permission: ['sp.accounts.staff-users.access'],
        },
    },
    {
        path: '/user/:id',
        name: 'account.user.view',
        exact: true,
        component: () => import('@/pages/user/View'),
        meta: {
            auth: true,
            permission: ['account.user.view'],
        },
    },
];

export default applyPageGroupKeyToParents(userRoute, { pageGroupKey });
