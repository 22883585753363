<template>
    <div class="layout-footer" v-if="!isEndUser">
        <div class="footer-logo-container">
            <img id="footer-logo" src="assets/layout/images/logo-emersion.svg" alt="Nimbus Logo" style="height: 1.5rem; width: auto;"/>
            <span class="app-name">| Nimbus Version {{ version }}</span>
        </div>
        <span class="copyright">Copyright &#169; 2021-2025 Emersion Systems</span>
    </div>

    <div class="layout-footer" v-else>
        <div class="footer-logo-container">
            <span class="app-name">{{ footerMessage }}</span>
        </div>
        <span class="copyright">Powered by Emersion Systems</span>
    </div>
</template>

<script>
import { useUserStore } from "@/stores/user-store";
import { useEUPStore } from '@/stores/eup-store';
import { computed } from 'vue';
export default {
    name: "AppFooter",
    setup() {
        const version = process.env.VUE_APP_VERSION ?? "1.0.0";

        const userStore = useUserStore();
        const eupStore = useEUPStore();
        const isEndUser = computed(() => userStore.isEup);
        const footerMessage = eupStore.eupSettings.footer_content;

        return {
            version,
            isEndUser,
            footerMessage
        }
    }
};
</script>