<script setup>
import { computed, getCurrentInstance } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        required: false,
    },
    iconSeverity: {
        type: String,
        default: 'text-secondary',
    },
    closable: {
        type: Boolean,
        default: false,
    },
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue']);
const { slots } = getCurrentInstance();

const iconClass = computed(() => {
    const classes = ['pi', props.icon, `text-${props.iconSeverity}`];
    return classes.filter(Boolean).join(' ');
});

const visible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<template>
    <Dialog
        v-model:visible="visible"
        class="nimbus-dialog-component nb-w-1/3"
        :closable="false"
        :show-header="false"
        modal
    >
        <!-- Close button -->
        <Button
            v-if="closable"
            @click="visible = false"
            icon="pi pi-times"
            severity="secondary"
            text rounded aria-label="Close"
            class="nimbus-dialog-component__close"
        />

        <i
            v-if="icon"
            class="nimbus-dialog-component-icon"
            :class="iconClass"></i>

        <slot />

        <div v-if="slots.cta" class="flex justify-content-end column-gap-3 mt-5">
            <slot name="cta" />
        </div>
    </Dialog>
</template>
