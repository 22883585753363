<template>
    <NimbusLoader is-route-loader />

    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
        <div class="layout-content-wrapper">
            <AppTopBar
                :topbarNotificationMenuActive="topbarNotificationMenuActive"
                :topbarUserMenuActive="topbarUserMenuActive"
                :topbarLanguageMenuActive="topbarLanguageMenuActive"
                :layoutMode="layoutMode"
                :menu="menu"
                :menuActive="menuActive"
                :staticMenuMobileActive="staticMenuMobileActive"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
                @menu-button-click="onMenuButtonClick"
                @search-click="toggleSearch"
                @topbar-notification="onTopbarNotificationMenuButtonClick"
                @topbar-user-menu="onTopbarUserMenuButtonClick"
                @topbar-language-menu="onTopbarLanguageMenuButtonClick"
                @right-menu-click="onRightMenuButtonClick"
                @right-menubutton-click="onRightMenuButtonClick"
                @change-language-click="onChangeLanguageClick"
            ></AppTopBar>

            <div class="layout-content">
                <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
                    <template v-if="$route.meta.breadcrumb">
                        <span>{{ $route.meta.breadcrumb[0].label }}</span>
                    </template>
                </div>

                <router-view :key="$route.path" />
            </div>
            <AppFooter/>
            <TimeTracking
                v-if="(store.timer > 0 || checkIfTicketPageIsActive) &&
                (can('ticket.time-log.create.own') || can('ticket.time-log.create.others'))"/>
        </div>

        <AppRightMenu :rightMenuActive="rightMenuActive" @right-menu-click="onRightMenuClick"></AppRightMenu>

        <AppConfig v-model:configActive="configActive" v-model:layoutMode="layoutMode" v-model:menuTheme="menuTheme"
                   v-model:colorScheme="colorScheme" @config-click="onConfigClick"
                   @config-button-click="onConfigButtonClick"></AppConfig>

        <AppSearch :searchActive="searchActive" @search-click="onSearchClick" @search-hide="onSearchHide"/>
        <NimbusAutoLogout v-if="userServices.isAuthenticated()"/>
    </div>
</template>

<script>
import { computed, onBeforeMount, onMounted } from 'vue';
import EventBus from '@/event-bus';
import AppTopBar from '@/AppTopbar';
import AppFooter from '@/AppFooter';
import AppConfig from '@/AppConfig';
import AppSearch from '@/AppSearch';
import AppRightMenu from '@/AppRightMenu';
import LanguageService from '@/service/LanguageService';
import { can } from "@/directives/permissions";
import { useAutoTimer } from "@/stores/auto-timer";
import TimeTracking from "@/components/time-tracking/TimeTracking";
import UserAuthenticationService from "@/service/UserAuthenticationService";
import NimbusAutoLogout from "@/components/nimbus/NimbusAutoLogout";
import { useAutoLogout } from "@/stores/auto-logout";
import { forceRemoveDataInAutoLogout } from "@/service/NimbusLSService";
import NimbusLoader from '@/components/NimbusLoader.vue';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/user-store';

export default {
    data() {
        return {
            menuApi: 'navigation', //to be changed
            menuActive: false,
            layoutMode: 'static',
            colorScheme: 'light',
            menuTheme: 'layout-sidebar-darkgray',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            userLanguageClick: false,
            topbarUserMenuActive: false,
            notificationMenuClick: false,
            topbarNotificationMenuActive: false,
            topbarLanguageMenuActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            menu: [],
            userService: null,
            parentMenuSelected: '',
            clicked: -1,
            hashName: window.location.hash.substr(1),
            selectedlanguage: 'en-gb',
            languageService: null,
            authStore: useAuthStore(),
        };
    },
    setup() {
        const store = useAutoTimer();
        const storeAutoLogout = useAutoLogout();
        const userServices = new UserAuthenticationService();
        const authStore = useAuthStore();
        const userStore = useUserStore();

        onBeforeMount(() => {
            if (!userServices.isAuthenticated() || userServices.getTwoFactorAuthentication() == 'null') {
                forceRemoveDataInAutoLogout();
            }
        });

        onMounted(async () => {
            // check here weather the user is on authenticated page
            if (!userServices.isAuthenticated()) {
                await store.resetStateValues();
                storeAutoLogout.removeLSInAutoLogout();
                storeAutoLogout.setActiveTimer()
            } else {
                await init();
                storeAutoLogout.resetStoreValues();
                storeAutoLogout.autoStartTimer()
                // let's start the timer if active
                if (store.checkTimerAndTicketIsActive) {
                    store.autoStartTimer();
                    store.isSideBarCollapsed = true;
                }
            }
        })

        async function init() {
            await Promise.all([
                authStore.loadDetails(),
                authStore.loadPermissions(),
                userStore.getUserTimezone(),
                userStore.getUserAccountSettings(),
            ]);
        }

        return {
            store,
            can,
            userServices,
            /**
             * Getters
             */
            checkIfTicketPageIsActive: computed(() => store.checkIfTicketPageIsActive),
            checkTimerAndTicketIsActive: computed(() => store.checkTimerAndTicketIsActive)
        }
    },
    async created() {
        this.languageService = new LanguageService();
        this.CUMULUS_URL_PLACEHOLDER = '[CUMULUS_URL]';

        if (this.userServices.isAuthenticated()) {
            await this.translateMenu();
        }
    },
    mounted() {
        /**
         * TODO (DEV-4640):
         *  Subject for removal since this is being persisted, but for now, this is retained for other pages to not
         *  create an issue.
         */
        EventBus.on('translateMenu', () => {
            this.translateMenu();
        });
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-slim': this.layoutMode === 'slim',
                    'layout-horizontal': this.layoutMode === 'horizontal',
                    'layout-sidebar-dim': this.colorScheme === 'dim',
                    'layout-sidebar-dark': this.colorScheme === 'dark',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.layoutMode === 'static',
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': !this.$primevue.config.ripple,
                },
                this.colorScheme === 'light' ? this.menuTheme : '',
            ];
        },
    },
    components: {
        NimbusLoader,
        AppTopBar,
        AppFooter,
        AppConfig,
        AppSearch,
        AppRightMenu,
        TimeTracking,
        NimbusAutoLogout
    },
    watch: {
        $route(from, to) {
            this.menuActive = false;
            this.$toast.removeAllGroups();
            this.toggleNavOnRouteChange();
        },
    },
    methods: {
        async onChangeLanguageClick(lang) {
            this.languageService.setSelectedLanguage(lang);
            await this.languageService.callApiTranslation();
            location.reload();
        },
        checkActiveMenu(parentMenu, url) {
            if ((this.hashName.includes(url) || this.hashName.fullPath == '/') && !this.parentMenuSelected) {
                this.parentMenuSelected = parentMenu;

                let parentElement = document.getElementById(this.parentMenuSelected);
                if (parentElement == null || parentElement == undefined) {
                    setInterval(
                        function () {
                            if (this.clicked == -1) {
                                parentElement = document.getElementById(this.parentMenuSelected);

                                if (parentElement != null) {
                                    this.nextUlCollapsed();
                                } else {
                                    this.clicked = -1;
                                }
                            }
                        }.bind(this),
                        2000
                    );
                } else {
                    parentElement.click();
                    this.clicked = -1;
                }
            }
        },
        async translateMenu() {
            try {
                await this.authStore.loadNavigations();
                const res = this.authStore.navigations;

                let responseMenu = res.pages;
                let parentGroups = res.parentPages;

                let originalMenu = [];
                let processedMenu = {};
                let items = [];

                processedMenu.label = this.$t('lbl_main_menu', 'Main Menu'); //Default Title
                for (let object = 0; object < responseMenu.length; object++) {
                    let menu = {};
                    let menuItems = [];

                    let menuObject = responseMenu[object];

                    menu.id = menuObject.id;
                    menu.label = menuObject.name;
                    menu.menu_key_id = menuObject.page_group_key;
                    menu.page_group_key = menuObject.page_group_key;
                    menu.icon = menuObject.icon;

                    menuObject.pages = menuObject.pages ? menuObject.pages : [];
                    //parent lookup
                    for (let parent = 0; parent < parentGroups.length; parent++) {
                        if (parentGroups[parent].parent_group_id == menu.id) {
                            //insert the page from the parent on the current pages
                            const pageLength = menuObject.pages.length;
                            menuObject.pages[pageLength] = parentGroups[parent];
                        }
                    }

                    //iterate pages
                    for (let page = 0; page < menuObject.pages.length; page++) {
                        let item = {};

                        if (menuObject.pages[page] != undefined) {
                            let itemMenuObject = menuObject.pages[page];

                            item.id = itemMenuObject.id;
                            item.label = itemMenuObject.name;
                            item.menu_key_id = itemMenuObject.page_key;
                            item.icon = itemMenuObject.icon;

                            let uri = this.replacePlaceHolder(itemMenuObject.uri);
                            if (this.parseExternalLink(uri)) {
                                item.uri = uri;
                            } else {
                                item.to = uri;
                            }

                            this.checkActiveMenu(menu.label, uri);

                            // if pages is an array it means there are items
                            if (Array.isArray(itemMenuObject.pages)) {
                                item.items = [];

                                for (let subItem = 0; subItem < itemMenuObject.pages.length; subItem++) {
                                    let subItemMenu = {};

                                    subItemMenu.id = itemMenuObject.pages[subItem].id;
                                    subItemMenu.label = itemMenuObject.pages[subItem].name;
                                    subItemMenu.menu_key_id = itemMenuObject.pages[subItem].page_key;
                                    subItemMenu.icon = itemMenuObject.pages[subItem].icon;

                                    if (this.parseExternalLink(itemMenuObject.pages[subItem].uri)) {
                                        subItemMenu.url = itemMenuObject.pages[subItem].uri;
                                    } else {
                                        subItemMenu.to = itemMenuObject.pages[subItem].uri;
                                    }

                                    item.items.push(subItemMenu);
                                }
                            }
                            menuItems.push(item);
                        }
                    }

                    menu.items = menuItems;
                    items.push(menu);
                }
                processedMenu.items = items;

                originalMenu.push(processedMenu);
                this.menu = originalMenu;
            } catch (error) {
                console.error('Error: ', error);
            }
        },

        toggleNavOnRouteChange() {
            // below will simulate click to toggle menu if user directly changed url on address bar
            let hash = window.location.hash.substr(1).split('/')[1];
            this.hashName = `/${hash}`; // get the first segment uri with / to determine pagegroup to click
            if (this.menu.length === 0) {
                return false;
            }
            this.menu.forEach((menu) => {
                for (let menuIndex in menu.items) {
                    if (menu.items[menuIndex].items.find(({to}) => to == this.hashName)) {
                        this.parentMenuSelected = menu.items[menuIndex].menu_key_id;
                        // this.nextUlCollapsed();
                    }
                }
            });
        },

        nextUlCollapsed() {
            if (document.querySelector(`#${this.parentMenuSelected} ~ ul`).style.display == 'none') {
                // make sure to trigger when page group is collapsed
                let parentElement = document.getElementById(this.parentMenuSelected);
                parentElement.click();
                this.clicked = 1;
            }
        },

        parseExternalLink(url) {
            return url ? url.toString().includes('://') : url;
        },

        replacePlaceHolder(url) {
            return url ? url.replace(this.CUMULUS_URL_PLACEHOLDER, process.env.VUE_APP_CUMULUS_URL) : url;
        },

        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.userMenuClick) {
                this.topbarUserMenuActive = false;
            }

            if (!this.userLanguageClick) {
                this.topbarLanguageMenuActive = false;
            }

            if (!this.notificationMenuClick) {
                this.topbarNotificationMenuActive = false;
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isSlim() || this.isHorizontal()) {
                    EventBus.emit('reset-active-index');
                    this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.userMenuClick = false;
            this.userLanguageClick = false;
            this.rightMenuClick = false;
            this.notificationMenuClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;
            this.topbarNotificationMenuActive = false;
            this.topbarLanguageMenuActive = false;
            this.rightMenuActive = false;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && (this.isSlim() || this.isHorizontal())) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onTopbarUserMenuButtonClick(event) {
            this.userMenuClick = true;
            this.topbarUserMenuActive = !this.topbarUserMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarLanguageMenuButtonClick(event) {
            this.userLanguageClick = true;
            this.topbarLanguageMenuActive = !this.topbarLanguageMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.notificationMenuClick = true;
            this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        toggleSearch() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
        onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onRightMenuButtonClick(event) {
            this.rightMenuClick = true;
            this.rightMenuActive = !this.rightMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        isOverlay() {
            return this.layoutMode === 'overlay';
        },
        isDesktop() {
            return window.innerWidth > 1091;
        },
    },
};
</script>

<style lang="scss">
@import 'App.scss';
</style>
