import { applyPageGroupKeyToParents } from '@/utilities/routeUtils';

const pageGroupKey = 'page_group_accounts';

const userPortalUserRoute = [
    {
        path: '/portal-users',
        name: 'End User Portal Users',
        exact: true,
        component: () => import('@/pages/account/account-list/portal_users/Index'),
        meta: {
            title: 'Portal Users',
            auth: true,
            permission: ['sp.accounts.users.access'],
            prefix: `account_portal_users`
        },
    }
];

export default applyPageGroupKeyToParents(userPortalUserRoute, { pageGroupKey });